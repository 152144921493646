import Step from '@mui/material/Step';
import StepConnector from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { QuotePayload } from '../models/interfaces';

interface ProgessStepProps {
  steps: string[];
}

export const ProgressStepper: React.FC<ProgessStepProps> = ({ steps }) => {
  const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      '& $line': {
        borderColor: '#a86752',
      },
    },
    completed: {
      '& $line': {
        borderColor: '#71504d',
      },
    },
    line: {
      borderColor: '#c5b79a',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  })(StepConnector);

  const productSelected = useSelector((s: QuotePayload) => s.product);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        alignSelf: 'center',
        width: '100%',
      },
      button: {
        marginRight: theme.spacing(1),
      },
      instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    }),
  );

  function QontoStepIcon(props: StepIconProps) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {completed ? (
          <div className={classes.completedCircle} />
        ) : (
          <div className={classes.circle} />
        )}
      </div>
    );
  }

  const useQontoStepIconStyles = makeStyles({
    root: {
      color: '#c5b79a',
      display: 'flex',
      height: 22,
      alignItems: 'center',
    },
    active: {
      color: '#a86752',
    },
    completedCircle: {
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: '#71504d',
    },
    circle: {
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
    completed: {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
  });

  const progressStep = useSelector<QuotePayload>(
    (s) => s.stepCounter,
  ) as number;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={progressStep}
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>
              <StepperStyle productSelected={productSelected}>
                {label}
              </StepperStyle>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

const StepperStyle = styled.div<{ productSelected: string }>`
  @media screen and (max-width: ${(props) => props.productSelected === 'trellis' ? '570px' : '750px'}) {
    display: none;
  }
`;
