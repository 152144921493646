import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as ClipboardIcon } from '../Assets/MiscAssets/Clipboard.svg';
import {
  GateData,
  PanelData,
  QuotePayload,
  TrellisData,
} from '../models/interfaces';
import { ProductType, ProductTypeArray, ProductTypeName } from '../models/types';
import { altButton02SX, altButton00SX } from '../styles';
import { consumerSummaryText } from './ConsumerSummaryText';
import { debtorSummaryText } from './DebtorSummaryText';
import { SummaryItem } from './SummaryItem';

export interface QuoteSummaryProps {
  handleFinish: () => void;
  openSummary: boolean;
  setOpenSummary: (x: boolean) => void;
  productSelected: ProductTypeName;
  progressStep: number;
  backUrl: string;
  finishButtonText: string;
}

export const QuoteSummary: React.FC<QuoteSummaryProps> = ({
  handleFinish,
  openSummary,
  setOpenSummary,
  productSelected,
  progressStep,
  backUrl,
  finishButtonText,
}) => {
  const trellisData = useSelector<QuotePayload, TrellisData[]>((s) =>
    s.trellisData.filter((r) => r.added),
  );

  const panelData = useSelector<QuotePayload, PanelData[]>((s) =>
    s.panelData.filter((r) => r.added),
  );

  const gateData = useSelector<QuotePayload, GateData[]>((s) =>
    s.gateData.filter((r) => r.added),
  );

  const totalItems = trellisData.length + panelData.length + gateData.length;

  const renderSummaryItem = (productType: ProductTypeArray) => {
    //@ts-ignore
    return productType.map((r: ProductType, i: number) => {
      return <SummaryItem productData={r} index={i} key={i} />;
    });
  };

  const handleBack = () => {
    if (
      (productSelected === 'trellis' && progressStep === 5) ||
      (productSelected !== 'trellis' && progressStep === 6) ||
      progressStep === 7
    ) {
      // i am slowly become a genius
      window.location.href = `${backUrl}`;
    } else {
      setOpenSummary(false);
    }
  };

  const renderSummarySection = () => {
    return (
      <>
        {trellisData.length > 0 && (
          <QuoteSummarySectionStyle>
            <div className="SummarySectionHeading">Trellis</div>
            <Divider />
            {renderSummaryItem(trellisData)}
          </QuoteSummarySectionStyle>
        )}
        {panelData.length > 0 && (
          <QuoteSummarySectionStyle>
            <div className="SummarySectionHeading">Panels</div>
            <Divider />
            {renderSummaryItem(panelData)}
          </QuoteSummarySectionStyle>
        )}
        {gateData.length > 0 && (
          <QuoteSummarySectionStyle>
            <div className="SummarySectionHeading">Gates</div>
            <Divider />
            {renderSummaryItem(gateData)}
          </QuoteSummarySectionStyle>
        )}
        <div className="VerticalSpacer" />
        <Paper
          elevation={1}
          square
          style={{ position: 'sticky', bottom: 0, zIndex: 2, }}
        >
          <BottomStyle>
            {totalItems > 0 && (
              <>
                <div className="Total">
                  <span className="TotalText">
                    <b>Total</b>
                  </span>
                  <span className="NumItems">{itemsPrettier()}</span>
                </div>

                <Button
                  className="GetQuoteModalButton"
                  size="large"
                  sx={altButton02SX}
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    handleFinish();
                    setOpenSummary(false);
                  }}
                >
                  {finishButtonText}
                </Button>
              </>
            )}
          </BottomStyle>
        </Paper>
      </>
    );
  };

  const renderNoQuotesSection = () => {
    return (
      <NoQuotesStyle>
        <div className="Container">
          <ErrorOutlineIcon className="Icon" />
          {window.location.href.includes('/consumer')
            ? consumerSummaryText()
            : debtorSummaryText()}
        </div>

        <Button
          variant="contained"
          sx={altButton00SX}
          disableElevation
          onClick={() => handleBack()}
        >
          {(productSelected === 'trellis' && progressStep === 5) ||
            (productSelected !== 'trellis' && progressStep === 6) ||
            progressStep === 7
            ? 'Back to Start'
            : 'Back'}
        </Button>
      </NoQuotesStyle>
    );
  };

  const itemsPrettier = () => {
    return totalItems > 1 ? `${totalItems} items` : '1 item';
  };

  return (
    <>
      <ModalStyle totalItems={totalItems}>
        {totalItems >= 0 && (
          <IconButton
            className="ModalButton"
            onClick={() => setOpenSummary(true)}
            size="large"
          >
            <Tooltip title="Review Items">
              <div className="Clipboard">
                <ClipboardIcon className="ClipboardIcon" />
                <div className="TotalItems">{totalItems > 0 && totalItems}</div>
              </div>
            </Tooltip>
          </IconButton>
        )}
      </ModalStyle>

      <Dialog
        style={{ maxWidth: 800, margin: '0 auto' }}
        fullWidth
        fullScreen
        onClose={() => {
          setOpenSummary(false);
        }}
        open={openSummary}
      >
        <DialogTitle style={{ color: "#71504d" }}>
          <div className="ModalHeaderText">Quote Summary</div>
          <IconButton
            aria-label="close"
            style={{ position: 'absolute', right: 0, top: 0 }}
            onClick={() => setOpenSummary(false)}
            size="large"
          >
            <CloseIcon style={{ fontSize: 35, color: '#a86752' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ overflowX: 'hidden' }}>
          {totalItems === 0 ? renderNoQuotesSection() : renderSummarySection()}
        </DialogContent>
      </Dialog>
    </>
  );
};

const ModalStyle = styled.div<{ totalItems: number }>`
  z-index: 1000;

  .QuoteItemContainer {
    margin-top: 40px;
  }

  .Clipboard {
    position: relative;

    .TotalItems {  
      color: ${(props) => props.theme.green};
      position: absolute;
      font-size: medium;
      font-weight: 700;
      top: 14px;
      left: ${(props) => (props.totalItems > 9 ? '23%' : '34%')};
    }
  }
`;

const NoQuotesStyle = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px 20px;
  .BackButton {
    width: 150px;
    margin: 0 auto 15px auto;
  }
  .Container {
    height: 100%;
    display: grid;
    place-content: center;
    justify-items: center;
    .Text {
      color: ${(props) => props.theme.darkGreen};
      font-size: larger;
    }
    .Icon {
      color: ${(props) => props.theme.red};
      font-size: 150px;
    }
  }
`;

const BottomStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 30px 20px;
  align-items: center;

  .GetQuoteModalButton {
    height: 60px;
    width: 175px;
  }

  .Total {
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.darkGreen};
  }
`;

const QuoteSummarySectionStyle = styled.div`
  color: ${(props) => props.theme.darkGreen};

  margin: 0px 15px;
  margin-bottom: 20px;
  .SummarySectionHeading {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 1.25rem;
  }

  .IconPreview {
    width: 70px;
    height: 70px;
    align-self: center;
  }
`;
