import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, IconButton, Input, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { displaySummaryIcon } from '../Icons';
import { ProductTypeName } from '../models/types';
import {
  cap,
  useCurrentGateData,
  useCurrentPanelData,
  useCurrentTrellisData,
} from '../utils';
import { buttonSX } from '../styles';

export interface SelectQuantityProps {
  nextButtonText: string;
  productSelected: ProductTypeName;
  pushUrl: string;
}

export const SelectQuantity: React.FC<SelectQuantityProps> = ({
  nextButtonText,
  productSelected,
  pushUrl,
}) => {
  const [quantity, setQuantity] = useState<number | null>(1);
  const dispatch = useDispatch();
  const history = useHistory();

  const panelData = useCurrentPanelData();
  const gateData = useCurrentGateData();
  const trellisData = useCurrentTrellisData();

  const renderSummaryIcon = () => {
    switch (productSelected) {
      case 'gate':
        return displaySummaryIcon(gateData);
      case 'panel':
        return displaySummaryIcon(panelData);
      case 'trellis':
        return displaySummaryIcon(trellisData);
    }
  };

  // Adds user-created trellis to Redux, thus accessible to the user via the "View Quotes" button
  const handleNext = () => {
    history.push(`${pushUrl}/added`);

    dispatch({
      type: 'SET_FIELDS',
      productType: productSelected,
      fieldValue: [
        { fieldName: 'quantity', value: quantity },
        { fieldName: 'added', value: true },
      ],
    });

    dispatch({
      type: 'PROGRESS_STEP',
    });
  };

  const changeQuantity = (decrement: boolean) => {
    if ((decrement && quantity === 1) || quantity == null) return;
    decrement ? setQuantity(quantity - 1) : setQuantity(quantity + 1);
  };

  const renderSummaryText = () => {
    if (productSelected === 'panel') {
      return `${cap(panelData.picketProfile)} Style ${cap(
        panelData.style,
      )} Panel with ${cap(panelData.picketSize)} Pickets.`;
    } else if (productSelected === 'gate') {
      return `${cap(gateData.picketProfile)} Style ${cap(
        gateData.style,
      )} Gate with ${cap(gateData.shape.hingePlacement)} Hand Hinges and ${cap(
        panelData.railSize,
      )} Rails.`;
    } else {
      return `${cap(trellisData.style)} Style Trellis ${trellisData.framed ? 'with' : 'without'
        } Frame and ${cap(trellisData.fixing)} Fixing.`;
    }
  };

  return (
    <QuoteAdminStyle>
      <div className="MainContent">
        <Typography variant="h4" className="HeaderText SummaryText">
          {renderSummaryText()}
        </Typography>
        <div className="IconQuantityContainer">
          {renderSummaryIcon()}
          <div className="Quantity">
            <span className="QuantityLabel">Quantity </span>
            <div className="QuantityInput">
              <IconButton
                className="Subtract"
                onClick={() => {
                  changeQuantity(true);
                }}
                size="large"
              >
                <RemoveIcon />
              </IconButton>
              <Input
                disableUnderline={true}
                type="number"
                className="QuantityNumber"
                value={quantity}
                onChange={(x) => {
                  if (x.target.value.search(/^[0-9]{0,6}$/g) !== -1) {
                    setQuantity(parseInt(x.target.value) || null);
                  }
                }}
              />
              <IconButton
                className="Add"
                onClick={() => {
                  changeQuantity(false);
                }}
                size="large"
              >
                <AddIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      <Button
        onClick={() => handleNext()}
        variant="contained"
        sx={buttonSX}
        disabled={quantity == null || quantity < 1}
      >
        {nextButtonText}
      </Button>
    </QuoteAdminStyle>
  );
};

export const QuoteAdminStyle = styled.div`
  height: 98%;
  display: flex;
  flex-direction: column;

  .IconQuantityContainer {
    place-content: center;
    height: inherit;
    display: grid;
    .IconPreview {
      margin: 20px;
    }
  }

  .Quantity {
    align-self: center;
    flex-direction: column;
    display: flex;
    margin: 0px auto;

    .QuantityLabel {
      align-self: center;
      margin-top: 1.25rem;
      font-weight: 600;
      font-size: larger;
      color: #353434;
    }
    .QuantityInput {
      display: inline-flex;
      background: #ffffff;
      align-items: center;
      border-radius: 0.05rem;
      margin: 5px 0px;

      .QuantityNumber {
        font-weight: 600;
        font-size: 1.5rem;
        width: 50px;
        color: ${(props) => props.theme.darkGreen};
        input {
          text-align: center;
        }
      }
      .Add,
      .Subtract {
        font-size: larger;
        color: #699976;
      }
    }
  }

  .Icon {
    font-size: 150px;
    margin: auto;
  }
`;
