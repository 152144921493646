import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { trellisStyleIcon } from '../Icons';
import { ProductType } from '../models/types';
import { cap } from '../utils';
import { panelIcon, gateIcon } from '../Icons';

export interface SummaryItemProps {
  productData: ProductType;
  index: number;
}

export const SummaryItem: React.FC<SummaryItemProps> = ({
  productData,
  index,
}) => {
  const dispatch = useDispatch();

  const [summaryQuantity, setSummaryQuantity] = useState(
    productData.quantity.toString(),
  );

  const changeQuantity = (
    decrement: boolean,
    product: ProductType,
    item: number,
  ) => {
    const qtyInt = parseInt(summaryQuantity, 10);

    if (decrement && qtyInt === 1) return;
    if (decrement) {
      dispatch({
        type: 'CHANGE_QUANTITY',
        product: product.name,
        quantity: qtyInt - 1,
        index: item,
      });
      setSummaryQuantity((qtyInt - 1).toString());
    } else {
      dispatch({
        type: 'CHANGE_QUANTITY',
        product: product.name,
        quantity: qtyInt + 1,
        index: item,
      });
      setSummaryQuantity((qtyInt + 1).toString());
    }
  };

  const renderSummaryIcon = (productSelected: ProductType) => {
    const prod = productSelected;
    switch (prod.name) {
      case 'gate':
        return gateIcon(prod.shape.hingePlacement, prod.railSize, prod.picketProfile, prod.picketSize, prod.style);
      case 'panel':
        return panelIcon(prod.picketSize, prod.picketProfile, prod.style);
      case 'trellis':
        return trellisStyleIcon(
          prod.style,
          prod.shape.angle,
          false,
        );
    }
  };

  const handleDelete = (item: number, product: ProductType) => {
    dispatch({
      type: 'DELETE_ITEM',
      product: product.name,
      index: item,
    });
  };

  const renderProperties = (product: ProductType) => {
    if (product.name === 'panel') {
      return (
        <div className="Properties">
          <span className="PicketSize"> {cap(product.picketSize)} Picket</span>
          <span className="RailSize">{cap(product.railSize)} Rail</span>
          <div className="SummaryDimensions">
            <span className="Height">
              <span className="Label">Height:</span>
              <span className="Value">
                {product.shape.height}
                <span className="mm">mm</span>
              </span>
            </span>
            <span className="Width">
              <span className="Label">Width: </span>
              <span className="Value">
                {product.shape.width}
                <span className="mm">mm</span>
              </span>
            </span>
          </div>
        </div>
      );
    } else if (product.name === 'gate') {
      return (
        <div className="Properties">
          <span className="PicketSize"> {cap(product.picketSize)} Picket</span>
          <span className="RailSize">{cap(product.railSize)} Rail</span>
          <span className="GateStyle">{cap(product.style)} Top</span>
          <span className="HingePlacement">
            {cap(product.shape.hingePlacement)} Hinge
          </span>
          <div className="SummaryDimensions">
            <span className="Height">
              <span className="Label">Height:</span>
              <span className="Value">
                <span className="mm">{product.shape.gateHeight}mm</span>
              </span>
            </span>
            <span className="Width">
              <span className="Label">Width:</span>
              <span className="Value">
                <span className="mm">{product.shape.gateWidth}mm</span>
              </span>
            </span>
          </div>
        </div>
      );
    } else if (product.name === 'trellis') {
      return (
        <div className="Properties">
          <span className="TrellisStyle"> {cap(product.style)} Style</span>
          <span className="RailSize">
            {product.framed ? 'With Frame' : 'Without Frame'}
          </span>
          <div className="SummaryDimensions">
            <span className="Height">
              <span className="Label">Height:</span>
              <span className="Value">
                {product.shape.height1}
                <span className="mm">mm</span>
              </span>
            </span>
            <span className="Height">
              <span className="Label">Height:</span>
              <span className="Value">
                {product.shape.height2}
                {product.shape.height2 ? (
                  <span className="mm">mm</span>
                ) : (
                  <span className="mm">---</span>
                )}
              </span>
            </span>
            <span className="Width">
              <span className="Label">Width:</span>
              <span className="Value">
                {product.shape.width}
                <span className="mm">mm</span>
              </span>
            </span>
          </div>
        </div>
      );
    }
  };
  return (
    <SummaryItemStyle>
      {renderSummaryIcon(productData)}
      {renderProperties(productData)}
      <div className="Actions">
        <div className="Quantity">
          <div className="QuantityInput">
            <div className="QuantityButtons">
              <IconButton
                className="Subtract"
                onClick={() => {
                  changeQuantity(true, productData, index);
                }}
                size="large"
              >
                <RemoveIcon />
              </IconButton>
              <div className="QuantityNumber">{summaryQuantity}</div>
              <IconButton
                className="Add"
                onClick={() => {
                  changeQuantity(false, productData, index);
                }}
                size="large"
              >
                <AddIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <IconButton
          className="DeleteIcon"
          onClick={() => handleDelete(index, productData)}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </SummaryItemStyle>
  );
};

const SummaryItemStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 15px auto 30px auto;
  max-width: 500px;
  padding: .5rem 1rem;
  border: .005rem solid #c5b79a56;

  .Properties {
    display: flex;
    flex-direction: column;
    width: 115px;
    .SummaryDimensions {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .Height,
      .Width {
        display: inline-flex;
        justify-content: space-between;
        .Label {
          font-size: .85rem;
        }
        .Value {
          color: #353434;
          font-size: .85rem;
        }
        .mm {
          color: #71504d;
        }
      }
    }

    .TrellisStyle,
    .RailSize,
    .PicketSize,
    .GateStyle,
    .HingePlacement {
      font-size: 1.05rem;
      font-weight: bold;

      @media screen and (min-width: 360px) {
        font-size: medium;
      }
    }
  }

  .Actions {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 75px;
    color: #3f8079;

    @media screen and (min-width: 400px) {
      width: auto;
    }

    .DeleteIcon {
      align-self: center;
      color: #71504d;
    }

    .QuantityInput {
      padding: 5px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;

      .QuantityButtons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
      }

      .QuantityNumber {
        font-weight: 600;
        width: 50px;
        text-align: center;

        color: '#71504d';
        input {
          text-align: center;
        }
      }
      .Add,
      .Subtract {
        font-size: larger;
        padding: 0px;
        color: #375954;
      }
      .Add {
        padding: 0px;
      }
    }
  }
`;
